import React, { Component } from "react";
import DatePicker from "components/DateTimePickerWithRadioButton/DatePicker";
import Dialog from "@material-ui/core/Dialog";
import UIUtils from "util/ui_utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IntlMessages from "util/IntlMessages";
import { NEW_PAYSLIP_ACTION_TYPES } from "constants/constants";
import { DialogContent } from "@material-ui/core";

export class DateTimeWithPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: "",
      selectedMonth: "",
      selectedYear: "",
    };
  }

  updateDateTimePickerValueToUI = () => {
    const { initialDateTime } = this.props;

    if (
      typeof initialDateTime !== "undefined" &&
      initialDateTime !== null &&
      initialDateTime !== ""
    ) {
      const dateTime = new Date(initialDateTime);
      const day = dateTime.getDate(),
        month = dateTime.getMonth() + 1,
        year = dateTime.getFullYear();

      this.setState({
        selectedDay: day < 10 ? `0${day}` : day,
        selectedMonth: month < 10 ? `0${month}` : month,
        selectedYear: year,
      });
    }
  };

  componentDidMount() {
    this.updateDateTimePickerValueToUI();
  }

  handleDateChange = (day, month, year) => {
    this.setState({
      selectedDay: day < 10 ? `0${day}` : day,
      selectedMonth: month < 10 ? `0${month}` : month,
      selectedYear: year,
    });
  };

  /** ok button callback **/
  handleOKButtonAction = () => {
    const { callbackOkk } = this.props;
    const { selectedDay, selectedMonth, selectedYear } = this.state;

    try {
      this.props.callbackDateUpdate(
        `${selectedYear}-${selectedMonth}-${selectedDay}`,
        true
      );
      if (callbackOkk) {
        callbackOkk();
      }
    } catch (ex) {
      console.error("handleOKButtonAction : error : ", ex);
    }
  };

  /** cancel button callback **/
  handleCancelButtonAction = () => {
    try {
      const { callbackCancel } = this.props;

      if (callbackCancel) {
        callbackCancel();
      }
    } catch (ex) {
      console.error("handleCancelButtonAction : error : ", ex);
    }
  };

  render() {
    const { selectedDay, selectedMonth, selectedYear } = this.state;
    const { userTimeZone } = this.props;

    return (
      <Dialog open={this.props.isVisible} maxWidth="xs">
        <DialogTitle>
          <div className="payslip_date_picker_time_zone_label mb-2">
            <IntlMessages id="DIC_KEY_PAYSLIP_DATE_PICKER_TIME_ZONE_LABEL" />
          </div>
          <div className="payslip_date_picker_note">{userTimeZone}</div>
        </DialogTitle>

        <DialogContent>
          <DatePicker
            initialDay={selectedDay}
            initialMonth={selectedMonth}
            initialYear={selectedYear}
            onDateChange={this.handleDateChange}
            userTimeZone={userTimeZone}
          />
          <div className="mt-2 payslip_date_picker_note">
            <p>
              <IntlMessages id="DIC_KEY_PAYSLIP_PROCESSING_MESSAGE" />
            </p>
            <p>
              <IntlMessages id="DIC_KEY_PAYSLIP_DATE_PICKER_SECOND_NOTE" />
            </p>
          </div>
        </DialogContent>

        <DialogActions className="mr-4 mb-4">
          <div onClick={this.handleCancelButtonAction}>
            {UIUtils.getUIComponent({
              key: NEW_PAYSLIP_ACTION_TYPES.CANCEL_NEW_PAYSLIP,
              label_id: "DIC_MAIN_PAGE_CANCEL_NEW_PAY_SLIP",
              title: <IntlMessages id="DIC_MAIN_PAGE_CANCEL_NEW_PAY_SLIP" />,
              type: UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
              parent_class: "float_right",
            })}
          </div>
          <div onClick={this.handleOKButtonAction}>
            {UIUtils.getUIComponent({
              key: NEW_PAYSLIP_ACTION_TYPES.OK_NEW_PAYSLIP,
              label_id: "DIC_MAIN_PAGE_OK_NEW_PAY_SLIP",
              title: <IntlMessages id="DIC_MAIN_PAGE_OK_NEW_PAY_SLIP" />,
              type: UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
              parent_class: "float_right",
              onClick: this.handleTimeOptionChange,
            })}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DateTimeWithPopUp;
