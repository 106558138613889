export const REPORT_FILTERS_RESET_STATE = "REPORT_FILTERS_RESET_STATE";
export const REPORT_FILTERS_SET_CLASSIFICATION =
  "REPORT_FILTERS_SET_CLASSIFICATION";
export const REPORT_FILTERS_SET_CHANNEL = "REPORT_FILTERS_SET_CHANNEL";
export const REPORT_FILTERS_SET_SELECTED_CLIENT =
  "REPORT_FILTERS_SET_SELECTED_CLIENT";
export const REPORT_FILTERS_SET_START_DATE = "REPORT_FILTERS_SET_START_DATE";
export const REPORT_FILTERS_SET_END_DATE = "REPORT_FILTERS_SET_END_DATE";
export const REPORT_FILTERS_SET_DATE_RANGE = "REPORT_FILTERS_SET_DATE_RANGE";
export const REPORT_FILTERS_UPDATE_REFRESH_COUNTER =
  "REPORT_FILTERS_UPDATE_REFRESH_COUNTER";
export const REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE =
  "REPORT_FILTERS_CONNECT_UPDATE_DATE_RANGE";
export const REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE =
  "REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE";
export const REPORT_FILTERS_ELEARNING_SET_DATE_RANGE =
  "REPORT_FILTERS_ELEARNING_SET_DATE_RANGE";
export const REPORT_FILTERS_CONNECT_SET_DATE_RANGE =
  "REPORT_FILTERS_CONNECT_SET_DATE_RANGE";
export const REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE =
  "REPORT_FILTERS_COMPANY_POST_UPDATE_DATE_RANGE";
export const REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE =
  "REPORT_FILTERS_COMPANY_POST_SET_DATE_RANGE";
export const REPORT_FILTERS_CONNECT_COUNTRY_FILTER =
  "REPORT_FILTERS_CONNECT_COUNTRY_FILTER";
export const REPORT_FILTERS_CONNECT_COMPANY_STATUS =
  "REPORT_FILTERS_CONNECT_COMPANY_STATUS";
export const REPORT_FILTERS_ELEARNING_JOB_ROLE =
  "REPORT_FILTERS_ELEARNING_JOB_ROLE";
export const REPORT_FILTERS_DISABLE_APPLY_BUTTON =
  "REPORT_FILTERS_DISABLE_APPLY_BUTTON";
export const REPORT_FILTERS_API_CALLS_COMPLETE =
  "REPORT_FILTERS_API_CALLS_COMPLETE";
export const REPORT_FILTERS_API_CALLS_NUMBER =
  "REPORT_FILTERS_API_CALLS_NUMBER";
