import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/util";
import { baseUrl } from "../../../util/keys";
import {
  HTTP_TIMEOUT,
  URL_CONNECT_CASE_PROPORTION,
} from "../../../constants/Urls";

import {
  CONNECT_API_GET_CASE_PROPORTION_REQUEST,
  CONNECT_API_GET_CASE_PROPORTION_SUCCESS,
  CONNECT_API_GET_CASE_PROPORTION_FAILURE,
} from "../../../constants/ActionTypes";
import { REPORT_FILTERS_API_CALLS_COMPLETE } from "../../../constants/actions/reports/actionsReportFilters";

export function* getCaseProportion() {
  yield takeEvery(
    CONNECT_API_GET_CASE_PROPORTION_REQUEST,
    connectGetCaseProportion
  );
}

const fetchGetCaseProportion = async ({ payload }) =>
  await axios
    .post(
      baseUrl + URL_CONNECT_CASE_PROPORTION,
      payload,
      AppUtils.getHttpHeaders()
    )
    .then((authUser) => authUser)
    .catch((error) => error);

// worker saga: makes the api call when watcher saga sees the action
export function* connectGetCaseProportion(payload) {
  try {
    const response = yield call(fetchGetCaseProportion, payload);
    const { data, message } = response;
    if (data) {
      yield put({
        type: CONNECT_API_GET_CASE_PROPORTION_SUCCESS,
        caseProportion: data,
      });
    }
    if (message) {
      yield put({
        type: CONNECT_API_GET_CASE_PROPORTION_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CONNECT_API_GET_CASE_PROPORTION_FAILURE, error });
  } finally {
    yield put({ type: REPORT_FILTERS_API_CALLS_COMPLETE });
  }
}
