import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  enableMaintenanceBanner,
  showMaintenanceBanner,
  enableMaintenancePage,
  getDownTimeDetails,
  getDownTimeDetailsInProgress,
} from "../../actions/adminSettings/maintenanceBanner/ActionsMaintenanceBanner";
import IntlMessages from "util/IntlMessages";
import { APP_NAVIGATION_PATHS } from "constants/config";

export const MOBILE_VIEW = 768;
export const HTTP_REQUEST_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

export const HTTP_REQUEST_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  SERVER_STOPPED: 418,
};

let stateCopy = {};

/**This Component is called inside the App.js component, to be shown accross the application */
function DownTimeBanner(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.ReducerMaintenanceBanner);

  /**To make the state variable updated in side the call back of interceptor */
  stateCopy = state;

  /**call downtime api on mount of the component to get details if any*/
  useEffect(() => {
    dispatch(getDownTimeDetailsInProgress());
    dispatch(getDownTimeDetails());
  }, []);

  /**
   * This is a Axios interceptor to intercept every API call,
   * there are two call back functions, any one will be called on every api call done using axios.
   * first call back : is called when there is a response from any API.
   * second call back: is called when there is a error from any API.
   */
  axios.interceptors.response.use(
    (response) => {
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        /**If Banner is disabled  */
        if (stateCopy.enableBanner === false) {
          /**And downtime key is present then get the details */
          if (
            response.data["downtime"] === true &&
            (stateCopy.downTimeDetailsFetchStatus ===
              HTTP_REQUEST_STATUS.NOT_STARTED ||
              stateCopy.downTimeDetailsFetchStatus === HTTP_REQUEST_STATUS.FAIL)
          ) {
            dispatch(getDownTimeDetailsInProgress());
            dispatch(getDownTimeDetails());
          }
        }

        /**Previously Banner is enabled and if it's not found  */
        if (stateCopy.enableBanner === true) {
          /**then make the api call get the details */
          if (
            typeof response.data["downtime"] === "undefined" &&
            stateCopy.downTimeDetailsFetchStatus !==
              HTTP_REQUEST_STATUS.IN_PROGRESS &&
            !response.request.responseURL.includes("/downtime/next")
          ) {
            dispatch(getDownTimeDetailsInProgress());
            dispatch(getDownTimeDetails());
          }
        }

        /**disable the maintenance page, once response is available instead of error*/
        if (stateCopy.enableMaintenancePage === true) {
          dispatch(enableMaintenancePage(false));
        }
      }

      /**Always return response */
      return response;
    },
    (error) => {
      /**
       * If the error is 418 then hide the banner if enabled and redirect to
       * application landing page and show the maintenance page
       */
      if (
        error.response?.status === HTTP_REQUEST_STATUS_CODES.SERVER_STOPPED ||
        (!error.response && error.message === "Network Error")
      ) {
        /**disable if banner is displayed */
        dispatch(enableMaintenanceBanner(false));

        /**enable the maintenance page */
        dispatch(enableMaintenancePage(true));

        /**Navigate to signin page */
        history.push(APP_NAVIGATION_PATHS.LOGIN);
      }
      /**return error in case of any other error code */
      return error;
    }
  );

  const handleClick = () => {
    dispatch(showMaintenanceBanner(false));
  };

  return (
    <>
      {state.enableBanner === true &&
        state.showBanner === true &&
        window.innerWidth > MOBILE_VIEW && (
          <div className="row down_time_banner align-items-center">
            <div className="col-1" style={{ maxWidth: "5%" }}>
              <div className="maintenance_banner_icon float-right"></div>
            </div>
            <div className="col-10">
              <IntlMessages id="DIC_KEY_MAINTENANCE_BANNER_MESSAGE_PART1" />
              {state.downTimeStartDate === "" ? (
                <IntlMessages id="DIC_KEY_MAINTENANCE_BANNER_MESSAGE_PART3" />
              ) : (
                <span>{state.downTimeStartDate}</span>
              )}
              <IntlMessages id="DIC_KEY_MAINTENANCE_BANNER_MESSAGE_PART2" />
              <span>{state.diffInhrs > 0 ? state.diffInhrs : 6}</span>
              <IntlMessages id="DIC_KEY_MAINTENANCE_BANNER_MESSAGE_PART4" />
            </div>
            <div
              className="col-1"
              style={{ maxWidth: "5%", position: "absolute", right: "20PX" }}
            >
              <div
                className="maintenance_banner_cancel_icon float-right mr-2"
                onClick={handleClick}
              ></div>
            </div>
          </div>
        )}
      {props.children}
    </>
  );
}

export default DownTimeBanner;
